<template>
  <MDBModal
    class="modal fade"
    id="exampleModalLong"
    tabindex="-1"
    v-model="exampleModalLong"
    staticBackdrop
    fullscreen
  >
    <MDBModalHeader color="" :close="false">
        <!-- <button
          type="button"
          class="btn-close"
          @click="closeModal"
        >
          x
        </button> -->
        <section v-if="getSectionContent('Header')" class="" :class="getSectionCSSClass('Header')" v-html="getSectionContent('Header')">
          </section>
        

        <MDBBtn  class="button accent-button close-button" @click="closeModal">X</MDBBtn>
    </MDBModalHeader>
    <MDBModalBody>
      <main :class="contents.css_class"> 

        
           <div v-if="getSectionContent('Page_intro')" class="" :class="getSectionCSSClass('Page_intro')" v-html="getSectionContent('Page_intro')">
          </div>
          

          <section v-if="getSectionContent('Main_content')" :class="getSectionCSSClass('Main_content')"
          v-html="getSectionContent('Main_content')">
          </section>

<a class="button accent-button button-close-text" href="#" @click="closeModal">Close this screen</a>
          <!-- <section v-if="getSectionContent('Header')" class="hero intro container full-width-background" :class="getSectionCSSClass('Header')" v-html="getSectionContent('Header')">
          </section>

          <div v-if="getSectionContent('Page_intro')" class="page-intro intro container full-width-background" :class="getSectionCSSClass('Page_intro')" v-html="getSectionContent('Page_intro')">
          </div>

          <section v-if="getSectionContent('Main_content')" class="single-column container"  :class="getSectionCSSClass('Main_content')">
            <article class="content" v-html="getSectionContent('Main_content')">
            </article>
          </section> -->
      </main>

    </MDBModalBody>

    <!-- <MDBModalFooter >
      <MDBBtn color="secondary" rounded @click="closeModal"> Close </MDBBtn>
    </MDBModalFooter> -->
  </MDBModal>
</template>

<script>
// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap.js"
  import {
    MDBModal,
    MDBModalHeader,
    // MDBModalTitle,
    MDBModalBody,
    // MDBModalFooter,
    MDBBtn,
  } from 'mdb-vue-ui-kit';
  // import { ref } from 'vue';
  export default {
    components: {
      MDBModal,
      MDBModalHeader,
      // MDBModalTitle,
      MDBModalBody,
      // MDBModalFooter,
      MDBBtn,
    },
    props: {
      visible: Boolean(false),
      contents: Object,
    },
    data() {
      return {
        exampleModalLong: this.visible
      }
    },
    updated(){
      // console.log('updated visible='+window.screen.availWidth);
      this.exampleModalLong=this.visible

    },
    computed: {

      locale(){
        if(this.$i18n.locale == 'es'){
                  return 'Spanish';
              }
        return 'English';
      },
      display_content() {
          let localizedContent = '';
          
          for (let i = 0; i < this.contents.content_items.length; i++) {
              let content = this.contents.content_items[i].display_contents.filter(
                  (display_content) =>
                      display_content.language == this.locale
              );
              // console.log(content.length);
              if (content.length){
                  localizedContent += content[0].display_content;
              }
          }             
          return localizedContent;
      },
    },
    methods:{
      getSectionContent(name){
        let localizedContent = '';
        let content_item = this.contents.content_items.filter(
                      (content_item) =>
                          content_item.item_name == name
        );
        // console.log(content_item.length)
        if (content_item.length){
          // console.log(content_item[0].display_contents);
                  localizedContent = content_item[0].display_contents.filter(
                      (display_content) =>
                          display_content.language == this.locale
                  );
          if(localizedContent.length){
            return localizedContent[0].display_content;
          }
            
        }
        return localizedContent;
      },
      getSectionCSSClass(name){
        let localizedContent = '';
        let content_item = this.contents.content_items.filter(
                      (content_item) =>
                          content_item.item_name == name
        );
        if (content_item.length){
          // console.log(content_item[0].display_contents);
                  localizedContent = content_item[0].css_class;
            
        }
              return localizedContent;
      },
      clickLink(url){ 
          const propsData = { path: url+"/"+this.$i18n.locale } 
          this.$router.push(propsData)
          window.scrollTo(0,0);
      },
      closeModal(){
            this.$emit('close');
      },
    },
  };
</script>

<style scoped>
</style>