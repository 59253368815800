<template>
<div id="wrapper"> <div class="line-wrap" style="margin-left: 70px;"> <div id="item-1" class="state-item">item 1</div> <div id="item-2" class="state-item">item 2</div> </div> </div>
</template>

<script>

// import draggable from "vuedraggable"
import { jsPlumb } from 'jsplumb'

export default {
  name: 'MyJsplumb',
  components:{
    // draggable
  },
  data: function(){
    return{
  
    }
  },

  created: function(){
        window.addEventListener("orientationchange", this.handleOrientationChange);

  },
  methods:{
      repaint() {
        // this.orientationchange=true;
        console.log('repaint')
        // this.plumbIns.repaintEverything();
        // if (this.openModal==false)
          this.$router.go();
      },
      handleOrientationChange() {
        try{
           let orientation = window.screen.orientation.type;
            if (this.orientationchange && orientation !==  this.orientationchange){
              console.log('rotation')
              this.repaint();
            }
            this.orientationchange = orientation;
            console.log(this.orientationchange)
          // if (orientation === "portrait-primary") {
          //   // portrait mode
          //   alert(orientation);
          // } else if (orientation === "landscape-primary") {
          //   // landscape mode
          //   alert("landscape-primary");
          // }

        }catch(e){
          let orientation = '';

            switch (window.orientation) {
              case -90:
              case 90:
                // landscape
                orientation = 'landscape';
                break;
              default:
                // portrait
                //  console.log('portrait')
                orientation = 'portrait';
                break;
            }
            if (this.orientationchange && orientation !==  this.orientationchange){
              this.repaint();
            }
            this.orientationchange = orientation;
            console.log(this.orientationchange)

        }
       
      }
  },
  mounted () {
    this.handleOrientationChange()
    let plumbIns = jsPlumb.getInstance()
    plumbIns.ready(function () {
      plumbIns.connect({
      // Corresponding to the above basic concepts 
      source: 'item-1',
      target: 'item-2',
      anchor: ['Left', 'Right', 'Top', 'Bottom', [0.3, 0, 0, -1], [0.7, 0, 0, -1], [0.3, 1, 0, 1], [0.7, 1, 0, 1]],
      connector: ['StateMachine'],
      endpoint: 'Blank',
      overlays: [ ['Arrow', { width: 8, length: 8, location: 1 }] ], // overlay
      // Add the style 
      paintStyle: { stroke: '#aeaeae', strokeWidth: 2 }, // connector
      // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      })
    })
  },

}
</script>

<style >
#wrapper {
background:
radial-gradient(
ellipse at top left,
rgba(255, 255, 255, 1) 40%,
rgba(229, 229, 229, .9) 100%
);
height: 100vh;
padding: 60px 80px;
width: 100vw;
}
.state-item {
width: 80px;
height: 40px;
color: #606266;
background: #f6f6f6;
border: 2px solid rgba(0, 0, 0, 0.05);
text-align: center;
line-height: 40px;
font-family: sans-serif;
border-radius: 4px;
margin-right: 60px;
}
.line-wrap {
display: flex;
margin-bottom: 40px;
}
</style>