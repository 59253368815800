<template>
    <PhysicianLayout :contents="contents" />
</template>

<script>
import contentData from "@/assets/pages/contents-Step_epley.json";
import PhysicianLayout from '@/views/physicians/PhysicianLayout.vue'
// import { ref } from 'vue'

export default {
    components: {
        PhysicianLayout
    },
    // props: ['json_name'],
    data() {
        return {
            contents: contentData, 
        };
    },
}
</script>

<style lang="scss">
</style>

