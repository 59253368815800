<template>
  <div >
    <h1>vue-inner-image-zoom Demo</h1>
    <div style="margin-bottom: 30px;">
      <h2>Pan Example</h2>
      <inner-image-zoom
        :src="srcs[0]"
        :zoomSrc="srcs[1]"
        :fullscreenOnMobile="true"

        :zoomPreload="true"
      />
    </div>
    <div style="margin-bottom: 30px;">
      <h2>Drag Example</h2>
      <inner-image-zoom
        :src="srcs[0]"
        :zoomSrc="srcs[1]"
        moveType="drag"
        :fullscreenOnMobile="false"
        :width="750"
        :height="500"
        :hasSpacer="true"
      />
    </div>
    <div style="margin-bottom: 30px;">
      <h2>Zoom on Hover Example</h2>
      <inner-image-zoom
        :src="srcs[0]"
        :zoomSrc="srcs[1]"
        zoomType="hover"
        :fullscreenOnMobile="true"
        :zoomScale="0.9"
        :zoomPreload="true"
      />
    </div>
  </div>
</template>

<script>
import InnerImageZoom from '@/components/InnerImageZoom.vue';

export default {
  components: {
    InnerImageZoom
  },
//   props: ['srcs']
    data(){
        return {
            srcs:['/img/algorithm.png', '/img/algorithm.png']
        }
    }
};
</script>
