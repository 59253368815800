<template>
  <!-- test nav -->
  <nav class="site-header">

    <div class="logo">
      <router-link :to="{ name: 'LandingView'}" class="no-underline">
         <site-logo />
       </router-link>
    </div>
  
  </nav>
</template>

<script>
// import SwitchLanguage from '@/components/SwitchLanguage.vue'
import SiteLogo from '@/components/SiteLogo.vue'

export default {
    components: {
        SiteLogo,
        // SwitchLanguage
    },
    methods:{
      hyperClicked(){
        // console.log(this.$refs["nav-Checkbox"].checked)
        if (this.$refs["nav-Checkbox"].checked==true){
          this.$refs["nav-Checkbox"].checked = false;
        }

      }
    }
}
</script>

<style>

</style>