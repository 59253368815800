<template>
    <PhysicianLayout :contents="contents" />
</template>

<script>
import contentData from "@/assets/pages/contents-Step_Neurological_abnormality.json";
import PhysicianLayout from '@/views/physicians/PhysicianLayout.vue'

export default {
    components: {
        PhysicianLayout
    },
    data() {
        return {
            contents: contentData, 
        };
    },

}
</script>

<style lang="scss">
</style>

