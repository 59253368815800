import { createApp } from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import router from "./router";
import { globalCookiesConfig } from "vue3-cookies";

globalCookiesConfig({
    expireTimes: "365d",
    path: "",
    domain: "",
    secure: true,
    sameSite: "None",
});

createApp(App).use(router).use(i18n).mount("#app");
