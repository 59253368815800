<template>
  <footer class="site-footer">
    <div class="site-footer-content">
      <div class="box box-1">
        &copy; {{ new Date().getFullYear() }} {{ $t("footer") }}
      </div>
      <div class="box box-2">
        <router-link
          v-if="!isPhysician"
          :to="{ name: 'About', params: { lang: $i18n.locale } }"
          class="f-logo f-logo-1"
        >
          <img
            src="/img/logos/KP_Signature_Stacked_307_rgb_0817.png"
            alt="Kaiser Permanente logo"
          />
        </router-link>
        <router-link
          v-if="!isPhysician"
          :to="{ name: 'About', params: { lang: $i18n.locale } }"
          class="f-logo f-logo-2"
        >
          <img
            src="/img/logos/U-M_Logo-Hex.png"
            alt="University of Michigan logo"
          />
        </router-link>
        <router-link
          v-if="!isPhysician"
          :to="{ name: 'About', params: { lang: $i18n.locale } }"
          class="f-logo f-logo-3"
        >
          <img
            src="/img/logos/OSU-logo-stack-red-gray.png"
            alt="The Ohio State University logo"
          />
        </router-link>
        <div v-if="isPhysician" class="f-logo f-logo-1">
          <img
            src="/img/logos/KP_Signature_Stacked_307_rgb_0817.png"
            alt="Kaiser Permanente logo"
          />
        </div>
        <div v-if="isPhysician" class="f-logo f-logo-2">
          <img
            src="/img/logos/U-M_Logo-Hex.png"
            alt="University of Michigan logo"
          />
        </div>
        <div v-if="isPhysician" class="f-logo f-logo-3">
          <img
            src="/img/logos/OSU-logo-stack-red-gray.png"
            alt="The Ohio State University logo"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      isPhysician: false,
    };
  },
  mounted() {
    const path = this.$route.path;
    this.isPhysician = path.startsWith("/physician");
  },
};
</script>

<style scoped>
.site-footer {
  background-color: #fff;
  margin-top: 2rem;
  padding: 1rem 0;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
}
.site-footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column-reverse;
}
.box-1 {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.box-2 {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.f-logo {
  margin: 0 1rem;
}
.f-logo-1 {
  max-width: 115px;
}
.f-logo-2 {
  max-width: 55px;
}
.f-logo-3 {
  max-width: 70px;
}
@media screen and (min-width: 820px) {
  .site-footer-content {
    flex-direction: row;
  }
  .box-1 {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 0 2rem;
  }
}
</style>
