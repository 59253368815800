<template>
    <LandingNavMenu/>
  <main class="physician physician-home">
    <section class="single-column dizz-container" v-if="isActive=='-99'">
      <div class="nav-group content">
        <h4>This project is currently limited to Kaiser Permanente sites in Southern California.</h4>
      </div>
      <div class="nav-group content">
          <button @click="reload">Select Site</button>
      </div>
    </section>
    <section class="single-column dizz-container" v-else-if="isActive=='-1'">
      <div class="nav-group content">
        <h4>Study not activated for this site</h4>
      </div>
      
      <div class="nav-group content">
          <button @click="reload">Select Site</button>
      </div>
    </section>
    <section class="single-column dizz-container" v-else>
      <h3>Please pick your clinic site:</h3>

      <div class="nav-group content">
        <select @change="changeClinic($event)">
          <option value="">Please select...</option>
          <option v-for="(c, i) in clinics"  :key="`lang-${i}`" :value="c.active">
              {{ c.name }}
          </option>
        </select>
      </div>

      <div class="nav-group content">
          <button @click="next">Submit</button>
      </div>
    </section>
  </main>
  <FooterBlock />
</template>

<script>
import clinicData from "@/assets/clinics/clinics.json";
import LandingNavMenu from '@/components/LandingNavMenu.vue'
import FooterBlock from "@/components/FooterBlock.vue";
// import { useCookies } from "vue3-cookies";

export default {
  components: {
    LandingNavMenu,
    FooterBlock,
  },
  setup() {
    // const { cookies } = useCookies();
    // return { cookies };
  },
  // mounted() {
  //   this.isActive = this.cookies.get("isActive");
  //   // console.log(this.isActive);
  //   this.cookies.set("myCoookie", "abcdefg");
  //     // console.log(this.cookies.keys().join("\n"));

  //   console.log('isActive '+this.cookies.get("isActive"));
  //   if (this.isActive=='true'){
  //       const propsData = { name: "Physician_Home"} 
  //       this.$router.push(propsData)
  //   } 
  // },
  data() {
        return {
            clinics: clinicData.clinics, 
            selectStatus: null,
            isActive: null,
        };
  },
  methods: {
    changeClinic (event) {
      this.selectStatus = event.target.value
    },
    reload(){
       location.reload()
    },
    next(){
      // this.cookies.set('isActive', this.selectStatus);
      // string type
      // this.isActive = this.cookies.get('isActive')
      // console.log('active ' + this.cookies.get('isActive'));

      this.isActive = this.selectStatus;
      if (this.isActive>0){
        const propsData = { name: "Physician_Home"}
        this.$router.push(propsData)
      }else{
        const propsData1 = { name: "Physician_Clinics"} 
        this.$router.push(propsData1)
      }

    }
  },
};
</script>
