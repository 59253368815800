<template>
  <MDBBtn
    color="primary"
    aria-controls="exampleModalLongTitle"
    @click="exampleModalLong = true"
  >
    Launch demo modal
  </MDBBtn>

  <MDBModal
    class="modal fade"
    id="exampleModalLong"
    tabindex="-1"
    v-model="exampleModalLong"
  >
    <MDBModalHeader color="primary" :close="false">
        <button
          type="button"
          class="btn-close"
          @click="exampleModalLong = false"
        >
          x
        </button>
    </MDBModalHeader>
    <MDBModalBody>
      <main :class="contents.css_class"> 

          <section class="hero intro container full-width-background" :class="getSectionCSSClass('Header')" v-html="getSectionContent('Header')">
          </section>

          <div v-if="getSectionContent('Page_intro')" class="page-intro intro container full-width-background" :class="getSectionCSSClass('Page_intro')" v-html="getSectionContent('Page_intro')">
          </div>

          <section class="single-column container"  :class="getSectionCSSClass('Main_content')">
          <article class="content" v-html="getSectionContent('Main_content')">
              </article>
          </section>
      </main>

    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn color="secondary" @click="exampleModalLong = false"> Close </MDBBtn>
      <!-- <MDBBtn color="primary"> Save changes </MDBBtn> -->
    </MDBModalFooter>
  </MDBModal>


  <MDBBtn
    color="primary"
    aria-controls="exampleModalScrollableTitle"
    @click="exampleModalScrollable = true"
  >
    Launch demo modal
  </MDBBtn>
  <MDBModal
    id="exampleModalScrollable"
    tabindex="-1"
    labelledby="exampleModalScrollableTitle"
    v-model="exampleModalScrollable"
    scrollable
  >
    <MDBModalHeader>
      <MDBModalTitle id="exampleModalScrollableTitle"> Modal title </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      <main :class="contents.css_class"> 

          <section class="hero intro container full-width-background" :class="getSectionCSSClass('Header')" v-html="getSectionContent('Header')">
          </section>

          <div v-if="getSectionContent('Page_intro')" class="page-intro intro container full-width-background" :class="getSectionCSSClass('Page_intro')" v-html="getSectionContent('Page_intro')">
          </div>

          <section class="single-column container"  :class="getSectionCSSClass('Main_content')">
          <article class="content" v-html="getSectionContent('Main_content')">
              </article>
          </section>
      </main>


    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn color="secondary" @click="exampleModalScrollable = false"> Close </MDBBtn>
      <MDBBtn color="primary"> Save changes </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>

<script>
import contentData from "@/assets/pages/contents-About.json";

  import {
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
  } from 'mdb-vue-ui-kit';
  import { ref } from 'vue';
  export default {
    components: {
      MDBModal,
      MDBModalHeader,
      MDBModalTitle,
      MDBModalBody,
      MDBModalFooter,
      MDBBtn,
    },
    setup() {
      const exampleModalLong = ref(false);
      const exampleModalScrollable = ref(false);
      return {
        exampleModalScrollable,
        exampleModalLong,
      };
    },
    data() {
        return {
            contents: contentData, 
        };
    },
    computed: {
      locale(){
        if(this.$i18n.locale == 'es'){
                  return 'Spanish';
              }
        return 'English';
      },
      display_content() {
          let localizedContent = '';
          
          for (let i = 0; i < this.contents.content_items.length; i++) {
              let content = this.contents.content_items[i].display_contents.filter(
                  (display_content) =>
                      display_content.language == this.locale
              );
              // console.log(content.length);
              if (content.length){
                  localizedContent += content[0].display_content;
              }
          }             
          return localizedContent;
      },
    },
    methods:{
      getSectionContent(name){
        let localizedContent = '';
        let content_item = this.contents.content_items.filter(
                      (content_item) =>
                          content_item.item_name == name
        );
        // console.log(content_item.length)
        if (content_item.length){
          // console.log(content_item[0].display_contents);
                  localizedContent = content_item[0].display_contents.filter(
                      (display_content) =>
                          display_content.language == this.locale
                  );
          if(localizedContent.length){
            return localizedContent[0].display_content;
          }
            
        }
              return localizedContent;
      },
      getSectionCSSClass(name){
        let localizedContent = '';
        let content_item = this.contents.content_items.filter(
                      (content_item) =>
                          content_item.item_name == name
        );
        if (content_item.length){
          // console.log(content_item[0].display_contents);
                  localizedContent = content_item[0].css_class;
            
        }
              return localizedContent;
      },
      clickLink(url){ 
          const propsData = { path: url+"/"+this.$i18n.locale } 
          this.$router.push(propsData)
          window.scrollTo(0,0);
      },
    },
  };
</script>

