<template>
  <!-- test nav -->
  <nav class="site-header">

    <div class="logo">
      <router-link :to="{ name: 'Patient_Home', params: {lang: $i18n.locale} }" class="no-underline">
         <site-logo />
       </router-link>
    </div>

    <input type="checkbox" id="nav-Checkbox" ref="nav-Checkbox" class="hidden">
    <label for="nav-Checkbox" id="nav-Toggle" class="cursor-pointer">
        <!-- Heroicon name: outline/menu -->
        <svg id="svg-menu" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
           <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
    
        <!-- Heroicon name: outline/x -->
        <svg id="svg-close" class="h-6 w-6 hidden" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
           <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </label>

    <ul class="nav-Menu">
      
      <li class="hidden">
          <router-link :to="{ name: 'Patient_Home', params: {lang: $i18n.locale} }" class="no-underline">
          {{$t('menu.Home')}}
        </router-link>
        </li>


        <li class="hidden">
          <router-link :to="{ name: 'Brief_episodes', params: {lang: $i18n.locale} }" @click="hyperClicked">{{$t('menu.Brief_episodes')}}</router-link>
        </li>
        <li class="hidden">
           <router-link :to="{ name: 'Constant_Dizziness', params: {lang: $i18n.locale} }" @click="hyperClicked" 
           :class="{'router-link-active': subIsActive('/patient/Mild_dizziness')||subIsActive('/patient/Severe_dizziness')}">{{$t('menu.Constant_Dizziness')}}</router-link>
        </li>
        <li class="hidden">
          <router-link :to="{ name: 'Other_causes', params: {lang: $i18n.locale} }" @click="hyperClicked">{{$t('menu.Other_causes')}}</router-link>
        </li>
        <li class="hidden">
          <router-link :to="{ name: 'Tips', params: {lang: $i18n.locale} }" @click="hyperClicked">{{$t('menu.Tips')}}</router-link>
        </li>
        <li class="hidden">
          <router-link :to="{ name: 'FAQ', params: {lang: $i18n.locale} }" @click="hyperClicked">{{$t('menu.FAQ')}}</router-link>
        </li>
        <li class="hidden">
          <router-link :to="{ name: 'Dizziness_checklist', params: {lang: $i18n.locale} }" @click="hyperClicked">{{$t('menu.Dizziness_checklist')}}</router-link>
        </li>
        <li class="hidden">
          <router-link :to="{ name: 'About', params: {lang: $i18n.locale} }" @click="hyperClicked">{{$t('menu.About')}}</router-link>
        </li>
        <!-- <li class="physicians hidden">
          <router-link :to="{ name: 'Physician_Home' }" class="button" @click="hyperClicked">{{$t('menu.Physicians')}}</router-link>
        
        </li> -->
        <li class="hidden"><SwitchLanguage /></li>
    </ul>
<!-- <SwitchLanguage /> -->
 <!-- <section class="language-picker">
      <language-globe />
      <p class="language-en">
          <a class="active">en</a>
      </p>
      <p class="language-es">
          <a>es</a>
      </p>
  </section> -->
  
  </nav>
</template>

<script>
import SwitchLanguage from '@/components/SwitchLanguage.vue'
import SiteLogo from '@/components/SiteLogo.vue'

export default {
    components: {
        SiteLogo,
        SwitchLanguage
    },
    data(){
      // return {lang: this.$route.query.lang}
      // return {lang: this.$route.params.lang}

    },
    methods:{
      hyperClicked(){
        // console.log(this.$refs["nav-Checkbox"].checked)
        if (this.$refs["nav-Checkbox"].checked==true){
          this.$refs["nav-Checkbox"].checked = false;
        }
      },
      subIsActive(input) {
        const paths = Array.isArray(input) ? input : [input]
        return paths.some(path => {
          return this.$route.path.indexOf(path) === 0 // current path starts with this path string
        })
      }
    }
}
</script>

<style>

</style>