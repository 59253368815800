export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "main": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hello from ", _interpolate(_named("company")), "!"])},
    "new_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no messages"]), _normalize(["one message"]), _normalize([_interpolate(_named("count")), " messages"])])}
  },
  "menu": {
    "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "Brief_episodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief episodes"])},
    "Constant_Dizziness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Constant dizziness"])},
    "Other_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other causes"])},
    "Tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other tips to manage dizziness"])},
    "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "Dizziness_checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dizziness management checklist"])},
    "Physicians": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physicians"])},
    "Patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Website"])},
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
  },
  "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regents of the University of Michigan"])}
}