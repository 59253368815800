<template>
<!-- Cannot be removed!! They are modal components -->
<StrapiModal v-for="item in coords_list" :key="item.name" :visible="item.visible" :json_name="item.json_name" @close="closeModal(item.name)" />

    <PhysicianLayout :contents="contents" :coords_list="coords_list" @click="showModal"/>
</template>

<script>
import contentData from "@/assets/pages/contents-Step_unidirectional.json";
import PhysicianLayout from '@/views/physicians/PhysicianLayout.vue'
import StrapiModal  from '@/views/physicians/StrapiModal.vue'

export default {
    components: {
        PhysicianLayout,
        StrapiModal
    },
    data(){
        return {
        contents: contentData, 
        coords_list: [
            { name:"uniny", json_name: 'Unidirectional_horizontal_nystagmus', visible:false },
            ],
        }
    },
    methods: {
      showModal(name) {
        this.coords_list.find(content_item => content_item.name == name).visible = true;
      },
      closeModal(name) {
        this.coords_list.find(content_item => content_item.name == name).visible = false;
      },
    },
}
</script>

<style lang="scss">
</style>

