import { createRouter, createWebHistory } from "vue-router";
import AboutView from "@/views/patients/AboutView.vue";

import LandingView from "@/views/LandingView.vue";
import Patient_Home from "@/views/patients/Patient_Home.vue";
import Constant_Dizziness from "@/views/patients/Constant_Dizziness.vue";
import Brief_episodes from "@/views/patients/Brief_episodes.vue";
import Dizziness_checklist from "@/views/patients/Dizziness_checklist.vue";
import Mild_dizziness from "@/views/patients/Mild_dizziness.vue";
import Severe_dizziness from "@/views/patients/Severe_dizziness.vue";
import FAQView from "@/views/patients/FAQView.vue";
import Epley_warnings from "@/views/patients/Epley_warnings.vue";
import Other_causes from "@/views/patients/Other_causes.vue";
import TipsView from "@/views/patients/TipsView.vue";
import WarningsView from "@/views/patients/WarningsView.vue";

import Physician_Clinics from "@/views/physicians/Physician_clinics.vue";
import Physician_Home from "@/views/physicians/Physician_Home.vue";
import AlgorithmView from "@/views/physicians/AlgorithmView.vue";
import AlgorithmView_img from "@/views/physicians/AlgorithmView-img.vue";
import AlgorithmPage from "@/views/physicians/AlgorithmPage.vue";
import VideoLibView from "@/views/physicians/VideoLibView.vue";
import Step_Neurological_abnormalityView from "@/views/physicians/Step_Neurological_abnormalityView.vue";
import Step_introView from "@/views/physicians/Step_introView.vue";

import Step_BPPV_diagnosisView from "@/views/physicians/Step_BPPV_diagnosisView.vue";
import Step_neurologicalView from "@/views/physicians/Step_neurologicalView.vue";
import Step_nystagmusView from "@/views/physicians/Step_nystagmusView.vue";
import Step_unidirectional_diagnosisView from "@/views/physicians/Step_unidirectional_diagnosisView.vue";
import Step_unidirectionalView from "@/views/physicians/Step_unidirectionalView.vue";
import Step_vestibular_treatmentView from "@/views/physicians/Step_vestibular_treatmentView.vue";

import Step_dixhallpikeView from "@/views/physicians/Step_dixhallpikeView.vue";
import Step_dix_findingView from "@/views/physicians/Step_dix_findingView.vue";
import Step_vn_diagnosisView from "@/views/physicians/Step_vn_diagnosisView.vue";
import Step_epleyView from "@/views/physicians/Step_epleyView.vue";
import Step_walkView from "@/views/physicians/Step_walkView.vue";
import Step_dischargeView from "@/views/physicians/Step_dischargeView.vue";
import Step_non_specificView from "@/views/physicians/Step_non_specificView.vue";
import Step_vn_treatmentView from "@/views/physicians/Step_vn_treatmentView.vue";

import Resources_and_referencesView from "@/views/physicians/Resources_and_referencesView.vue";

import content1 from "@/views/test/content-1.vue";
import ModalBootstrap from "@/views/test/ModalBootstrap.vue";
import demoInner from "@/views/test/demoInner.vue";
import MyJsplumb from "@/views/test/MyJsplumb.vue";
import AlgorithmView_old from "@/views/test/AlgorithmView-old.vue";

const routes = [
  {
    path: "/",
    name: "LandingView",
    component: LandingView,
  },
  {
    path: "/patient/",
    redirect: { name: "Patient_Home", params: { lang: "en" } },
  },
  {
    path: "/patient/home/:lang",
    name: "Patient_Home",
    component: Patient_Home,
  },
  {
    path: "/physician",
    name: "Physician_Clinics",
    component: Physician_Clinics,
  },
  {
    path: "/physician/home",
    name: "Physician_Home",
    component: Physician_Home,
  },
  {
    path: "/About/:lang",
    name: "About",
    component: AboutView,
  },
  {
    path: "/MyJsplumb",
    name: "MyJsplumb",
    component: MyJsplumb,

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: "/content-1",
    name: "content-1",
    component: content1,
  },
  {
    path: "/physician/AlgorithmView",
    name: "AlgorithmView",
    component: AlgorithmView,
  },
  {
    path: "/physician/Algorithm",
    name: "AlgorithmView-img",
    component: AlgorithmView_img,
  },
  {
    path: "/physician/AlgorithmPage/:json_name",
    name: "AlgorithmPage",
    component: AlgorithmPage,
  },
  {
    path: "/physician/VideoLibView",
    name: "VideoLibView",
    component: VideoLibView,
  },
  {
    path: "/physician/Step_Neurological_abnormalityView",
    name: "Step_Neurological_abnormalityView",
    component: Step_Neurological_abnormalityView,
  },
  {
    path: "/physician/Step_introView",
    name: "Step_introView",
    component: Step_introView,
  },
  {
    path: "/physician/Step_BPPV_diagnosisView",
    name: "Step_BPPV_diagnosisView",
    component: Step_BPPV_diagnosisView,
  },
  {
    path: "/physician/Step_neurologicalView",
    name: "Step_neurologicalView",
    component: Step_neurologicalView,
  },
  {
    path: "/physician/Step_nystagmusView",
    name: "Step_nystagmusView",
    component: Step_nystagmusView,
  },
  {
    path: "/physician/Step_unidirectional_diagnosisView",
    name: "Step_unidirectional_diagnosisView",
    component: Step_unidirectional_diagnosisView,
  },
  {
    path: "/physician/Step_unidirectionalView",
    name: "Step_unidirectionalView",
    component: Step_unidirectionalView,
  },
  {
    path: "/physician/Step_vestibular_treatmentView",
    name: "Step_vestibular_treatmentView",
    component: Step_vestibular_treatmentView,
  },
  {
    path: "/physician/Step_dixhallpikeView",
    name: "Step_dixhallpikeView",
    component: Step_dixhallpikeView,
  },
  {
    path: "/physician/Step_dix_findingView",
    name: "Step_dix_findingView",
    component: Step_dix_findingView,
  },
  {
    path: "/physician/Step_vn_diagnosisView",
    name: "Step_vn_diagnosisView",
    component: Step_vn_diagnosisView,
  },
  {
    path: "/physician/Step_epleyView",
    name: "Step_epleyView",
    component: Step_epleyView,
  },
  {
    path: "/physician/Step_walkView",
    name: "Step_walkView",
    component: Step_walkView,
  },
  {
    path: "/physician/Step_dischargeView",
    name: "Step_dischargeView",
    component: Step_dischargeView,
  },
  {
    path: "/physician/Step_non_specificView",
    name: "Step_non_specificView",
    component: Step_non_specificView,
  },
  {
    path: "/physician/Step_vn_treatmentView",
    name: "Step_vn_treatmentView",
    component: Step_vn_treatmentView,
  },
  {
    path: "/physician/Resources_and_references",
    name: "Resources_and_references",
    component: Resources_and_referencesView,
  },
  {
    path: "/ModalBootstrap",
    name: "ModalBootstrap",
    component: ModalBootstrap,
  },
  {
    path: "/demoInner",
    name: "demoInner",
    component: demoInner,
  },
  {
    path: "/test",
    name: "test",
    component: AlgorithmView_old,
  },
  {
    path: "/patient/Constant_Dizziness/:lang",
    name: "Constant_Dizziness",
    component: Constant_Dizziness,
  },
  {
    path: "/patient/Brief_episodes/:lang",
    name: "Brief_episodes",
    component: Brief_episodes,
  },
  {
    path: "/patient/Dizziness_checklist/:lang",
    name: "Dizziness_checklist",
    component: Dizziness_checklist,
  },
  {
    path: "/patient/Mild_dizziness/:lang",
    name: "Mild_dizziness",
    component: Mild_dizziness,
  },
  {
    path: "/patient/Severe_dizziness/:lang",
    name: "Severe_dizziness",
    component: Severe_dizziness,
  },
  {
    path: "/patient/FAQ/:lang",
    name: "FAQ",
    component: FAQView,
  },
  {
    path: "/patient/Epley_warnings/:lang",
    name: "Epley_warnings",
    component: Epley_warnings,
  },
  {
    path: "/patient/Other_causes/:lang",
    name: "Other_causes",
    component: Other_causes,
  },
  {
    path: "/patient/Tips/:lang",
    name: "Tips",
    component: TipsView,
  },
  {
    path: "/patient/Warnings/:lang",
    name: "Warnings",
    component: WarningsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
