<script>
import PhysicianNavMenu from "@/views/physicians/PhysicianNavMenu.vue";
import FooterBlock from "@/components/FooterBlock.vue";

export default {
  components: {
    PhysicianNavMenu,
    FooterBlock,
  },
  data() {
    return {
      header: "",
      header_class: "",
      intro: "",
      intro_class: "",
      main: "",
      main_class: "",
    };
  },
  mounted() {
    import(`@/assets/pages/contents-${this.$route.params.json_name}`).then(
      (module) => {
        const strapi = module.default.content_items;

        const locale = this.$i18n.locale == "es" ? "Spanish" : "English";

        strapi.forEach((item) => {
          const css = item.css_class;
          const name = item.item_name;
          let content = item.display_contents.filter(
            (display_content) => display_content.language == locale
          );
          const markup = content[0].display_content;
          switch (name) {
            case "Header":
              this.header = markup;
              this.header_class = css;
              break;
            case "Page_intro":
              this.intro = markup;
              this.intro_class = css;
              break;
            case "Main_content":
              this.main = markup;
              this.main_class = css;
              break;
          }
        });
      }
    );
  },
};
</script>

<template>
  <PhysicianNavMenu />
  <main class="physician physician-home">
    <div class="algorithm-content theme-blue">
      <section v-if="header" v-html="header" :class="header_class"></section>
      <section v-if="intro" v-html="intro" :class="intro_class"></section>
      <section v-if="main" v-html="main" :class="main_class"></section>
    </div>
  </main>
  <FooterBlock />
</template>

<style scoped>
.algorithm-content {
  padding: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
</style>
