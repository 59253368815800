<template>
  <!-- test nav -->
  <nav class="site-header physician-nav">

    <div class="logo">
      <router-link :to="{ name: 'Physician_Home'}" class="no-underline">
         <site-logo />
       </router-link>
    </div>

    <input type="checkbox" id="nav-Checkbox" ref="nav-Checkbox" class="hidden">
    <label for="nav-Checkbox" id="nav-Toggle" class="cursor-pointer">
        <!-- Heroicon name: outline/menu -->
        <svg id="svg-menu" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
           <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
    
        <!-- Heroicon name: outline/x -->
        <svg id="svg-close" class="h-6 w-6 hidden" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
           <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </label>

    <ul class="nav-Menu">
        <li class="hidden">
          <router-link :to="{ name: 'AlgorithmView-img' }" @click="hyperClicked">Evaluation & Management Algorithm</router-link>
        </li>
        <li class="hidden">
           <router-link :to="{ name: 'Step_introView' }" @click="hyperClicked"
           :class="{'router-link-active': subIsActive('/physician/Step_')}">Step by step</router-link>
        </li>
        <li class="hidden">
          <router-link :to="{ name: 'VideoLibView' }" @click="hyperClicked">Video Library</router-link>
        </li>
        <li class="physicians hidden">
          <router-link :to="{ name: 'Patient_Home', params: {lang: $i18n.locale}  }" class="button" @click="hyperClicked">{{$t('menu.Patients')}}</router-link>
        </li>
    </ul>
  
  </nav>
</template>

<script>
import SiteLogo from '@/components/SiteLogo.vue'
// import { useCookies } from "vue3-cookies";

export default {
    components: {
        SiteLogo,
    },
    setup() {
      // const { cookies } = useCookies();
      // return { cookies };
    },
    data(){
      return {lang: this.$route.query.lang}
    },
    methods:{
      hyperClicked(){
        // console.log(this.$refs["nav-Checkbox"].checked)
        if (this.$refs["nav-Checkbox"].checked==true){
          this.$refs["nav-Checkbox"].checked = false;
        }
      },
      subIsActive(input) {
        const paths = Array.isArray(input) ? input : [input]
        return paths.some(path => {
          return this.$route.path.indexOf(path) === 0 // current path starts with this path string
        })
      }
    },
    mounted() {
      // this.isActive = this.cookies.get("isActive");
      // console.log('menu ' + this.isActive);
      // if (this.isActive!='true'){
      //     const propsData = { name: "Physician_Clinics"} 
      //     this.$router.push(propsData)
      // } 
    },
}
</script>

<style>

</style>