<template>
  <PhysicianNavMenu />
  <main class="physician physician-home">
    <section class="single-column dizz-container">
      <div class="nav-group content">
        <router-link :to="{ name: 'AlgorithmView-img' }">
          <div>
            <h3>Evaluation & Management Algorithm</h3>
            <img src="/img/icon-phys-algorithm.png" />
          </div>
        </router-link>
        <!-- <router-link :to="{ name: 'AlgorithmView' }">
          <div>
            <h3>Evaluation & Management Algorithm (old)</h3>
            <p>
              This is a link will ONLY show on dev and is here to compare old
              and new Algorithm approaches
            </p>
            <img src="/img/icon-phys-algorithm.png" />
          </div>
        </router-link> -->
        <router-link :to="{ name: 'Step_introView' }">
          <div>
            <h3>Step by step</h3>
            <p>Guide through the Evaluation & Management Algorithm</p>
            <img src="/img/icon-phys-step-by-step.png" />
          </div>
        </router-link>
        <router-link :to="{ name: 'VideoLibView' }">
          <div>
            <h3>Video Library</h3>
            <img src="/img/icon-phys-video.png" />
          </div>
        </router-link>
        <router-link :to="{ name: 'Resources_and_references' }">
          <div>
            <h3>Resources, References, Reporting</h3>
            <img src="/img/icon-phys-video.png" />
          </div>
        </router-link>
      </div>
    </section>
  </main>
  <FooterBlock />
</template>

<script>
import PhysicianNavMenu from "@/views/physicians/PhysicianNavMenu.vue";
import FooterBlock from "@/components/FooterBlock.vue";

export default {
  components: {
    PhysicianNavMenu,
    FooterBlock,
  },
  data() {
    return {};
  },
};
</script>
