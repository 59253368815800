<template>
    <PatientLayout :contents="contents" />
</template>

<script>
import contentData from "@/assets/pages/contents-Home.json";
import PatientLayout from '@/views/patients/PatientLayout.vue'

export default {
    components: {
        PatientLayout
    },
    data() {
        return {
            contents: contentData, 
        };
    },
    updated() {
        console.log('patient home')
        this.$router.go();
    }
}
</script>

<style lang="scss">

    .sans {margin-top: .5rem}
    .other {
        display: flex;
        gap: 1rem;
        display:grid;
        grid-template-columns: repeat( auto-fit, minmax(140px, 1fr) );
    }

    .other h3 {
        font-size: 1.25rem;
        font-family: "Source Sans Pro", Franklin Gothic Medium, Franklin Gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
    }

</style>
