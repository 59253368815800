<template>
<PhysicianNavMenu/>

<!-- Cannot be removed!! They are modal components -->
<StrapiModal v-for="item in coords_list" :key="item.name" :visible="item.visible" :json_name="item.json_name" @close="closeModal(item.name)" />


<main class="physician video-library">
  <section class="single-column ">
    <article class="algorithm-article theme-green content">
      <header class="algorithm-category  category hero">
        <h4>Test</h4>
      </header>
      
      <a href="#" id="dixhallpike" @click="clickBlock('dixhallpike')">
        <div class="algorithm-element arrow-right">
         <p>Dix-Hallpike Test</p>
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>
    </article>

    <article class="algorithm-article theme-yellow content">
      <header class="algorithm-category  category hero">
        <h4>Finding</h4>
      </header>

      <a href="#" id="triggeredny" @click="clickBlock('triggeredny')">
        <div class="algorithm-element">
          <!-- <p>Triggered, transient Nystagmus</p> -->
          <p>Triggered and transient nystagmus (a peripheral pattern)</p>
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>

      <a href="#" id="uniny" @click="clickBlock('uniny')">
        <div class="algorithm-element">
          <!-- <p>Unidirectional horizontal nystagmus</p> -->
          <p>Unidirectional horizontal nystagmus (Typically Peripheral Vestibular)</p>
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>


      <a href="#" id="gazeevoked" @click="clickBlock('gazeevoked')">
        <div class="algorithm-element">
          <!-- <p>Gaze-Evoked Nystagmus</p> -->
          <p>Gaze-evoked nystagmus (a central pattern)</p>
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>

      <a href="#" id="vertical" @click="clickBlock('vertical')">
        <div class="algorithm-element">
          <!-- <p>Vertical Nystagmus</p> -->
          <p>Spontaneous vertical nystagmus (a central pattern)</p>
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>

    </article>

    <article class="algorithm-article theme-green content">
      <header class="algorithm-category  category hero">
        <h4>Treatment</h4>
      </header>

      <a href="#" id="epley" @click="clickBlock('epleyRight')">
        <div class="algorithm-element">
         <p>Epley Maneuver on the Right Side</p>
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>
      <a href="#" id="epley" @click="clickBlock('epleyLeft')">
        <div class="algorithm-element">
          <p>Epley Maneuver on the Left Side</p>
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>
    </article>
  </section>
</main>
<FooterBlock/>
</template>

<script>
import PhysicianNavMenu from '@/views/physicians/PhysicianNavMenu.vue'
import StrapiModal  from '@/views/physicians/StrapiModal.vue'
import FooterBlock from '@/components/FooterBlock.vue'


// import IconRead from '@/components/IconRead.vue'
import IconVideo from '@/components/IconVideo.vue'
// import IconHelpcircle from '@/components/IconHelpcircle.vue'

export default {
  // name: 'ErrorAlert',
  components: {
    PhysicianNavMenu,
    FooterBlock,
    StrapiModal,
    // IconRead,
    IconVideo,
    // IconHelpcircle,  
  },
  props: {

  },
  data(){
    return {
      coords_list: [
        { name:"dixhallpike", json_name: 'Video_dixhallpike', visible:false },
        { name:"triggeredny", json_name: 'Video_triggered', visible:false },
        { name:"uniny", json_name: 'Video_unidirectional', visible:false },
        { name:"epleyRight", json_name: 'Video_epleyright', visible:false },
        { name:"epleyLeft", json_name: 'Video_epleyleft', visible:false },
        { name:"gazeevoked", json_name: 'Video_gazeevoked', visible:false },
        { name:"vertical", json_name: 'Video_vertical', visible:false },
      ],
    }
  },
    methods: {
      showModal(name) {
        this.coords_list.find(content_item => content_item.name == name).visible = true;
      },
      closeModal(name) {
        this.coords_list.find(content_item => content_item.name == name).visible = false;
      },
      clickBlock(name){
        this.showModal(name);
      },
    },

}
</script>

<style lang="scss">
   .video-library {
     header, a {
        margin: 1rem 0;
     }
   }
</style>