<template>
<svg width="24" height="24">
  <circle cx="12" cy="12" r="12" fill="#2782AC" />
  <text x="50%" y="52%" text-anchor="middle" fill="white" font-size="16px" font-family="Arial" dy=".3em">?</text>
</svg>


</template>
<script>
export default {
  name: 'IconHelpcircle',
  data() {
        return {
        };
  },
}
</script>
