<template>
  <main class="algorithm" style="padding: 60px 60px 120px">
    <article class="algorithm-article ask theme-purple">
      <header class="algorithm-category">
        <h4>Ask</h4>
      </header>

      <!--this code makes a diamond shape with the text and yes/no inside it-->
      <section
        class="decision-point-diamond"
        id="isnew"
        @click="clickBlock('isnew')"
      >
        <a href="#" class="decision-question">
          Is there a new neurological deficit (e.g., BE-FAST)?
          <icon-helpcircle />
        </a>

        <div class="no">no</div>
        <div class="yes">yes</div>
      </section>

      <a href="#" id="neuroab" @click="clickBlock('neuroab')">
        <div class="algorithm-element">
          Neurologic abnormality
          <div class="read-icon">
            <icon-read />
          </div>
        </div>
      </a>
    </article>

    <article class="algorithm-article ask theme-purple">
      <header class="algorithm-category">
        <h4>Ask</h4>
      </header>

      <section
        class="decision-point-diamond"
        id="straightside"
        @click="clickBlock('straightside')"
      >
        <div class="decision-question">
          Is there spontaneous (when looking straight ahead) or gaze-evoked
          nystagmus?
        </div>
        <div class="no">no</div>
        <div class="yes">yes</div>
      </section>
    </article>

    <article class="algorithm-article theme-green">
      <header class="algorithm-category">
        <h4>Test</h4>
      </header>

      <a href="#" id="dixhallpike" @click="clickBlock('dixhallpike')">
        <div class="algorithm-element arrow-right">
          Dix-Hallpike Test
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>
    </article>

    <article class="algorithm-article theme-yellow">
      <header class="algorithm-category">
        <h4>Finding</h4>
      </header>

      <a href="#" id="triggeredny" @click="clickBlock('triggeredny')">
        <div class="algorithm-element">
          Triggered, transient Nystagmus
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>

      <a href="#" id="otherny" @click="clickBlock('otherny')">
        <div class="algorithm-element">
          Other nystagmus
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>

      <a href="#" id="nony" @click="clickBlock('nony')">
        <div class="algorithm-element">
          No nystagmus
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>

      <a href="#" id="uniny" @click="clickBlock('uniny')">
        <div class="algorithm-element">
          Unidirectional horizontal nystagmus
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>

      <a href="#" id="otherpatternny" @click="clickBlock('otherpatternny')">
        <div class="algorithm-element">
          Any other pattern of nystagmus
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>
    </article>

    <article class="algorithm-article theme-blue">
      <header class="algorithm-category">
        <h4>Typical Diagnosis</h4>
      </header>

      <a href="#" id="bppv" @click="clickBlock('bppv')">
        <div class="algorithm-element">
          BPPV
          <div class="read-icon">
            <icon-read />
          </div>
        </div>
      </a>
      <a href="#" id="vestibular" @click="clickBlock('vestibular')">
        <div class="algorithm-element">
          Vestibular Neuritis Central Lesion Vestibular migraine
          <div class="read-icon">
            <icon-read />
          </div>
        </div>
      </a>
      <a href="#" id="nonspec" @click="clickBlock('nonspec')">
        <div class="algorithm-element">
          Non specific dizziness
          <div class="read-icon">
            <icon-read />
          </div>
        </div>
      </a>
      <a href="#" id="univest" @click="clickBlock('univest')">
        <div class="algorithm-element">
          Unilateral vestibulopathy (eg, Vestibular Neuritis)
          <div class="read-icon">
            <icon-read />
          </div>
        </div>
      </a>
    </article>

    <article class="algorithm-article theme-green">
      <header class="algorithm-category">
        <h4>Treatment</h4>
      </header>

      <a href="#" id="epley" @click="clickBlock('epley')">
        <div class="algorithm-element">
          Epley Maneuver
          <div class="video-icon">
            <icon-video />
          </div>
        </div>
      </a>

      <a href="#" id="medivest" @click="clickBlock('medivest')">
        <div class="algorithm-element">
          Medication Vestibular Exercises
          <div class="read-icon">
            <icon-read />
          </div>
        </div>
      </a>

      <a href="#" id="vestptmed" @click="clickBlock('vestptmed')">
        <div class="algorithm-element">
          Vestibular PT Medication
          <div class="read-icon">
            <icon-read />
          </div>
        </div>
      </a>
    </article>

    <article class="algorithm-article theme-default discharge">
      <header class="algorithm-category">
        <h4>Discharge</h4>
      </header>

      <section class="decision-point-diamond" id="nowalkrisk">
        <div class="decision-question decision-question-column">
          Patient newly unable to walk 10 feet,
          <span>&mdash; or &mdash; </span>
          Uncontrolled risk factors for stroke
        </div>

        <div class="no">no</div>
        <div class="yes">yes</div>
      </section>

      <a href="#" id="okdis" @click="clickBlock('okdis')">
        <div class="algorithm-element">
          <div>OK for discharge</div>
          <p></p>
          <div>
            Follow-up with PCP
            <p></p>
            <p>Limit symptomatic medicines to &lt; 5 days</p>
          </div>

          <div class="read-icon">
            <icon-read />
          </div>
        </div>
      </a>

      <a href="#" id="consult" @click="clickBlock('consult')">
        <div class="algorithm-element">
          Consider consult / discussion with neurology
          <div class="read-icon">
            <icon-read />
          </div>
        </div>
      </a>
    </article>
  </main>

  <!-- Cannot be removed!! They are modal components -->
  <StrapiModal
    v-for="item in coords_list"
    :key="item.name"
    :visible="item.visible"
    :json_name="item.json_name"
    @close="closeModal(item.name)"
  />
</template>

<script>
import StrapiModal from "@/views/physicians/StrapiModal.vue";

import IconRead from "@/components/IconRead.vue";
import IconVideo from "@/components/IconVideo.vue";
import IconHelpcircle from "@/components/IconHelpcircle.vue";
import { jsPlumb } from "jsplumb";
import { useMobileDetection } from "vue3-mobile-detection";

export default {
  // name: 'ErrorAlert',
  components: {
    StrapiModal,
    IconRead,
    IconVideo,
    IconHelpcircle,
  },
    setup() {
        const { isMobile } = useMobileDetection();
        return { isMobile };
    },
  props: {},
  data() {
    return {
      // plumbIns: null,
      coords_list: [
        { name: "isnew", json_name: "Signs_of_a_stroke", visible: false },
        {
          name: "neuroab",
          json_name: "Neurological_Abnormality",
          visible: false,
        },
        { name: "dixhallpike", json_name: "Dix-Hallpike_Test", visible: false },
        { name: "bppv", json_name: "BPPV", visible: false },
        {
          name: "vestibular",
          json_name: "Vestibular_Neuritis",
          visible: false,
        },
        {
          name: "nonspec",
          json_name: "Non_specific_dizziness",
          visible: false,
        },
        {
          name: "univest",
          json_name: "Unilateral_vestibulopathy",
          visible: false,
        },
        { name: "epley", json_name: "Epley_Maneuver", visible: false },
        {
          name: "medivest",
          json_name: "Medication_Vestibular_Exercises",
          visible: false,
        },
        {
          name: "vestptmed",
          json_name: "Vestibular_PT_Medication",
          visible: false,
        },
        { name: "consult", json_name: "Consider_consult", visible: false },
        { name: "okdis", json_name: "Followup_with_PCP", visible: false },
        {
          name: "straightside",
          json_name: "Types_of_Nystagmus",
          visible: false,
        },
        { name: "triggeredny", json_name: "Nystagmus_Types", visible: false },
        { name: "otherny", json_name: "Nystagmus_Types", visible: false },
        { name: "nony", json_name: "Nystagmus_Types", visible: false },
        {
          name: "uniny",
          json_name: "Unidirectional_horizontal_nystagmus",
          visible: false,
        },
        {
          name: "otherpatternny",
          // json_name: "Unidirectional_horizontal_nystagmus",
          json_name: "Other_pattern_nystagmus",
          visible: false,
        },
      ],
      openModal: false,
      orientationchange: "",
    };
  },
  methods: {
    showModal(name) {
      this.coords_list.find(
        (content_item) => content_item.name == name
      ).visible = true;
      this.openModal = true;
    },
    closeModal(name) {
      this.coords_list.find(
        (content_item) => content_item.name == name
      ).visible = false;
      this.openModal = false;
      this.handleOrientationChange();
    },
    clickBlock(name) {
      this.showModal(name);
      this.openModal = true;
    },
    repaint() {
      // this.plumbIns.repaintEverything();
      if (!this.openModal) {
        this.$router.go();
      }
    },
    handleOrientationChange() {
      console.log("handleOrientationChange");
      try {
        let orientation = window.screen.orientation.type;
        if (this.orientationchange && orientation !== this.orientationchange) {
          this.repaint();
        }
        this.orientationchange = orientation;
      } catch (e) {
        let orientation = "";

        switch (window.orientation) {
          case -90:
          case 90:
            // landscape
            orientation = "landscape";
            break;
          default:
            // portrait
            orientation = "portrait";
            break;
        }
        if (this.orientationchange && orientation !== this.orientationchange) {
          this.repaint();
        }
        this.orientationchange = orientation;
      }
    },
    resize() {
        this.repaint();
    },
  },
  created() {
    if (this.isMobile()){
        window.addEventListener("orientationchange", this.handleOrientationChange);
    } else {
        window.addEventListener("resize", this.resize);
    }
    // try {
    //   console.log(this.isMobile())
    //   if (window.screen.orientation.type.indexOf('primary')>-1){
    //     window.addEventListener("resize", this.resize);
    //   }
    // } catch (e) {
    //     this.isMobile=true;
    //     window.addEventListener("orientationchange", this.handleOrientationChange);
    // }
    console.log(this.isMobile());
  },
  mounted() {
    if (this.isMobile)
      this.handleOrientationChange();
    let plumbIns = jsPlumb.getInstance();
    console.log("jsPlumb mounted");
    // let plumbIns=this.plumbIns;
    plumbIns.ready(() => {
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "isnew",
        target: "neuroab",
        anchor: [
          "Left",
          "Right",
          "Top",
          "Bottom",
          [0.3, 0, 0, -1],
          [0.7, 0, 0, -1],
          [0.3, 1, 0, 1],
          [0.7, 1, 0, 1],
        ],
        connector: ["StateMachine"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "isnew",
        target: "straightside",
        anchor: [
          "Left",
          "Right",
          "Top",
          "Bottom",
          [0.3, 0, 0, -1],
          [0.7, 0, 0, -1],
          [0.3, 1, 0, 1],
          [0.7, 1, 0, 1],
        ],
        connector: ["StateMachine"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });

      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "straightside",
        target: "dixhallpike",
        anchor: [
          "Left",
          "Right",
          "Top",
          "Bottom",
          [0.3, 0, 0, -1],
          [0.7, 0, 0, -1],
          [0.3, 1, 0, 1],
          [0.7, 1, 0, 1],
        ],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "dixhallpike",
        target: "triggeredny",
        anchor: [
          "Left",
          "Right",
          "Top",
          "Bottom",
          [0.3, 0, 0, -1],
          [0.7, 0, 0, -1],
          [0.3, 1, 0, 1],
          [0.7, 1, 0, 1],
        ],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "dixhallpike",
        target: "otherny",
        anchor: ["Right", "Left"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "dixhallpike",
        target: "nony",
        anchor: ["Right", "Left"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });

      // plumbIns.connect({
      //   // Corresponding to the above basic concepts
      //   source: 'straightside',
      //   target: 'nony',
      //   anchor: ['Bottom', 'Left'],
      //   connector: ['Flowchart'],
      //   endpoint: 'Blank',
      //   overlays: [ ['Arrow', { width: 20, length: 10, location: 1}] ], // overlay
      //   // Add the style
      //   paintStyle: { stroke: '#aeaeae', strokeWidth: 2 }, // connector
      //   // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      // });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "straightside",
        target: "uniny",
        anchor: ["Bottom", "Left"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "straightside",
        target: "otherpatternny",
        anchor: ["Bottom", "Left"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });

      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "triggeredny",
        target: "bppv",
        anchor: [
          "Left",
          "Right",
          "Top",
          "Bottom",
          [0.3, 0, 0, -1],
          [0.7, 0, 0, -1],
          [0.3, 1, 0, 1],
          [0.7, 1, 0, 1],
        ],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "otherny",
        target: "vestibular",
        anchor: ["Right", "Left"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "nony",
        target: "nonspec",
        anchor: ["Right", "Left"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "uniny",
        target: "univest",
        anchor: ["Right", "Left"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });

      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "bppv",
        target: "epley",
        anchor: [
          "Left",
          "Right",
          "Top",
          "Bottom",
          [0.3, 0, 0, -1],
          [0.7, 0, 0, -1],
          [0.3, 1, 0, 1],
          [0.7, 1, 0, 1],
        ],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "vestibular",
        target: "medivest",
        anchor: ["Right", "Left"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "nonspec",
        target: "medivest",
        anchor: ["Right", "Left"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });

      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "univest",
        target: "vestptmed",
        anchor: ["Right", "Bottom"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });

      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "nowalkrisk",
        target: "okdis",
        anchor: [
          "Right",
          "Left",
          "Top",
          "Bottom",
          [0.3, 0, 0, -1],
          [0.7, 0, 0, -1],
          [0.3, 1, 0, 1],
          [0.7, 1, 0, 1],
        ],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "nowalkrisk",
        target: "consult",
        anchor: ["Bottom", "Left"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });

      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "epley",
        target: "nowalkrisk",
        anchor: ["Right", "Left"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "medivest",
        target: "nowalkrisk",
        anchor: ["Right", "Left"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "vestptmed",
        target: "nowalkrisk",
        anchor: ["Right", "Left"],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 10, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });

      plumbIns.connect({
        // Corresponding to the above basic concepts
        source: "otherpatternny",
        target: "neuroab",
        anchor: ["Bottom", [0.7, 1, 0, 1]],
        connector: ["Flowchart"],
        endpoint: "Blank",
        overlays: [["Arrow", { width: 20, length: 18, location: 1 }]], // overlay
        // Add the style
        paintStyle: { stroke: "#aeaeae", strokeWidth: 2 }, // connector
        // endpointStyle: { fill: '#909399', outlineStroke: '#606266', outlineWidth: 1 } // endpoint
      });
    });
  },
};
</script>

<style lang="scss">
.decision-point-diamond {
  background-color: #5c2da8;
}
.decision-point-diamond.alert {
  background-color: #bf4018;
}

.decision-question,
.decision-question-column,
.decision-point-diamond,
.decision-point-diamond a {
  color: #fff;
}
</style>
