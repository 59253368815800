<template>
  <!-- <PhysicianNavMenu/> -->
  <!-- Test 1: vue zoom in: not work  -->
  <div style="margin-bottom: 30px">
    <h2>Pan Example</h2>
    <inner-image-zoom
      :src="img.src"
      :zoomSrc="img.src"
      moveType="drag"
      :fullscreenOnMobile="false"
      :hasSpacer="true"
      :width="img.innerWidth"
      :height="img.innerHeight"
      :coordsList="coords_list"
      @open="showModal"
    />
  </div>

  <!-- <div style="margin-bottom: 30px;">
<iframe src="/pdfjs-2.13.216-dist/web/viewer.html?file=/img/algorithm.pdf" height="700px" width="100%"></iframe>
</div> -->

  <!-- Test 2: Img map area -->
  <!-- <div class="image-map-container" >
    <img :src="img.src" alt="Workplace" usemap="#image-map"  :width="img.width" :height="img.height" >
    <div class="map-selector"></div>
</div>
<map name="image-map" id="image-map">
  <area shape="rect" v-for="(k, i) in coords_list" :key="i" :coords="newCoord(k.coord)" href="#" @click="clickBlock(k.name)" :title="k.name">
</map>  -->

  <!-- Cannot be removed!! They are modal components -->
  <!-- <AboutModal :visible="coords_list[0].visible" @close="closeModal(coords_list[0].name)" />
<VideoModal :visible="coords_list[1].visible" @close="closeModal(coords_list[1].name)" /> -->

  <!-- Test 3: css list -->
  <!-- <main id="container">
	<ul id="continents3">
    <li class="northamerica">
        <a href="#" @click="clickBlock(coords_list[0].name)">

            <span>
                <strong>North America</strong> 
                Population: 528,720,588
            </span>
        </a>
    </li>
    <li class="southamerica">
        <a href="#" @click="clickBlock(coords_list[1].name)">
            <span>

                <strong>South America</strong>
                Population: 385,742,554
            </span>
        </a>
    </li>    
    <li class="asia">
    	<a href="http://en.wikipedia.org/wiki/Asia">
            <span>
                <strong>Asia</strong>

                Population: 3,879,000,000
            </span>
        </a>
    </li>
    <li class="australia">
    	<a href="http://en.wikipedia.org/wiki/Australia">
            <span>
                <strong>Australia</strong>
                Population: 21,807,000
            </span>

        </a>
    </li>
    <li class="africa">
        <a href="http://en.wikipedia.org/wiki/Africa">
        <span>
            <strong>Africa</strong>
            Population: 922,011,000
        </span>
        </a>

    </li>
    <li class="europe">
        <a href="http://en.wikipedia.org/wiki/Europe">
            <span>
                <strong>Europe</strong>
                Population: 731,000,000
            </span>
        </a>
    </li>    
</ul>
</main> -->
</template>

<script>
// import PhysicianNavMenu from '@/components/PhysicianNavMenu.vue'
import InnerImageZoom from "@/components/InnerImageZoom.vue";
// import AboutModal  from '@/views/physicians/StrapiModal.vue'
// import VideoModal  from '@/views/physicians/VideoModal.vue'

export default {
  // name: 'ErrorAlert',
  components: {
    // PhysicianNavMenu,
    InnerImageZoom,
    // AboutModal,
    // VideoModal,
  },
  props: {
    // msg: String,
    // visible: Boolean,
  },
  data() {
    return {
      img: {
        src: "/img/algorithm.png",
        width: 3077,
        height: 940,
      },
      windowHeight: 0,
      windowWidth: 0,
      coords_list: [
        {
          name: "Neurologic abnormality",
          url_name: "Physician_Home",
          coord: [78, 470, 390, 550],
          visible: false,
        },
        {
          name: "Dix-Hallpike Test",
          url_name: "Physician_Home",
          coord: [858, 222, 1175, 310],
          visible: false,
        },
        {
          name: "Triggered, transient Nystagmus",
          url_name: "Physician_Home",
          coord: [1230, 222, 1550, 315],
          visible: false,
        },
        {
          name: "Other Nystagmus",
          url_name: "Physician_Home",
          coord: [1230, 338, 1550, 415],
          visible: false,
        },
        {
          name: "No nystagmus",
          url_name: "Physician_Home",
          coord: [1230, 446, 1550, 526],
          visible: false,
        },
        {
          name: "Unidirectional horizontal nystagmus",
          url_name: "Physician_Home",
          coord: [1230, 620, 1550, 710],
          visible: false,
        },
        {
          name: "Any other pattern of nystagmus",
          url_name: "Physician_Home",
          coord: [1230, 728, 1550, 820],
          visible: false,
        },
        {
          name: "BPPV",
          url_name: "Physician_Home",
          coord: [1605, 222, 1920, 310],
          visible: false,
        },
        {
          name: "Vestibular Neuritis Central Lesion Vestibular migraine",
          url_name: "Physician_Home",
          coord: [1605, 340, 1920, 440],
          visible: false,
        },
        {
          name: "Nin specific dizziness",
          url_name: "Physician_Home",
          coord: [1605, 450, 1920, 535],
        },
        {
          name: "Unilateral vestibulopathy (eg, Vestibular Neuritis)",
          url_name: "Physician_Home",
          coord: [1605, 620, 1920, 710],
          visible: false,
        },
        {
          name: "Epley Maneuver",
          url_name: "Physician_Home",
          coord: [1980, 222, 2295, 310],
          visible: false,
        },
        {
          name: "Medication Vestibular Exercises",
          url_name: "Physician_Home",
          coord: [1980, 335, 2295, 435],
          visible: false,
        },
        {
          name: "Vestibular PT Medication",
          url_name: "Physician_Home",
          coord: [1980, 620, 2295, 710],
          visible: false,
        },
        {
          name: "Follow-up with PCP",
          url_name: "Physician_Home",
          coord: [2730, 230, 3045, 415],
          visible: false,
        },
        {
          name: "Consider consult/discussion with neurology,",
          url_name: "Physician_Home",
          coord: [2730, 565, 3045, 680],
          visible: false,
        },
      ],
      isZoomIn: false,
      hover: false,
    };
  },
  methods: {
    showModal(name) {
      this.isZoomIn = false;
      this.coords_list.find(
        (content_item) => content_item.name == name
      ).visible = true;
      // console.log(this.coords_list);
    },
    closeModal(name) {
      this.isZoomIn = true;
      this.coords_list.find(
        (content_item) => content_item.name == name
      ).visible = false;
      // console.log(this.coords_list);
      // this.$router.go()
    },
    clickBlock(name) {
      // const propsData = { name: name, params: {lang:this.$i18n.locale} }
      // this.$router.push(propsData)
      this.showModal(name);
    },
    log(a) {
      console.log(a);
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      // console.log(window .innerHeight)
    },
    newCoord(coords) {
      var newX = this.windowWidth / this.img.width;
      var newCord =
        coords[0] * newX +
        "," +
        coords[1] * newX +
        "," +
        coords[2] * newX +
        "," +
        coords[3] * newX;
      // console.log(newCord)
      return newCord;
    },
  },
  computed: {},
  created() {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
};
</script>

<style scoped>
#container {
  /* width: 580px;
	height: 268px; */
  margin: 0 auto;
}

h1 {
  margin-bottom: 20px;
  border-bottom: 2px solid #000;
  font: bold 28px Arial, Helvetica, sans-serif;
  text-align: center;
  padding-bottom: 5px;
}

h1 a {
  color: #000;
  text-decoration: none;
}

ul#continents1,
ul#continents2,
ul#continents3 {
  list-style: none;
  width: 3077px;
  height: 940px;
  position: relative;
  background: url(../../../public/img/algorithm-page.png) no-repeat 0 0;
  /* background-size: 50%; */
}

ul#continents1 li,
ul#continents2 li,
ul#continents3 li {
  position: absolute;
}

.northamerica {
  width: 227px;
  height: 142px;
  top: 470px;
  left: 78px;
}

.southamerica {
  width: 108px;
  height: 130px;
  top: 220px;
  left: 858px;
}

.africa {
  width: 120px;
  height: 140px;
  top: 83px;
  left: 209px;
}

.europe {
  width: 120px;
  height: 84px;
  top: 1px;
  left: 211px;
}

.asia {
  width: 215px;
  height: 175px;
  top: 1px;
  left: 283px;
}

.australia {
  width: 114px;
  height: 95px;
  top: 152px;
  left: 432px;
}

ul#continents1 li a,
ul#continents2 li a,
ul#continents3 li a {
  display: block;
  outline: none;
  height: 100%;
}

ul#continents1 li a,
ul#continents2 li a {
  text-indent: -9000px;
}

/* ul#continents2 li a:hover, ul#continents3 li a:hover {
	background: url(../../../public/img/algorithm-page.png) no-repeat 0 0;	
}

ul#continents2 li.northamerica a:hover, ul#continents3 li.northamerica a:hover {
	background-position: 0 -270px;
}

ul#continents2 li.southamerica a:hover, ul#continents3 li.southamerica a:hover {
	background-position: -858px -250px;
}

ul#continents2 li.africa a:hover, ul#continents3 li.africa a:hover {
	background-position: -209px -417px;
}

ul#continents2 li.europe a:hover, ul#continents3 li.europe a:hover {
	background-position: -22px -427px;
}

ul#continents2 li.asia a:hover, ul#continents3 li.asia a:hover {
	background-position: -363px -268px;
}

ul#continents2 li.australia a:hover, ul#continents3 li.australia a:hover {
	background-position: -412px -455px;
} */

ul#continents3 li a span {
  display: none;
}

ul#continents3 li a:hover span {
  display: block;
}

ul#continents3 li a:hover span {
  display: block;
  padding: 5px;
  width: 150px;
  background: #000;
  position: relative;
  top: 50%;
  font: 11px Arial, Helvetica, sans-serif;
  opacity: 0.75;
  filter: alpha(opacity=75);
  color: #fff;
}

ul#continents3 li a:hover span strong {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
  text-decoration: underline;
}
</style>
