<template>
<PatientNavMenu/>
<main class="patient" :class="contents.css_class"> 

    <section class="hero intro dizz-container full-width-background" :class="getSectionCSSClass('Header')" v-html="getSectionContent('Header')">
    </section>

    <div v-if="getSectionContent('Page_intro')" class="page-intro intro dizz-container full-width-background" :class="getSectionCSSClass('Page_intro')" v-html="getSectionContent('Page_intro')">
    </div>




    <section class="single-column dizz-container"  :class="getSectionCSSClass('Main_content')">
		<article class="content" v-html="getSectionContent('Main_content')">
        </article>
    </section>
</main>
<footer class="nav-footer dizz-container">
    <div class="content">
         
          <router-link :to="{ name: 'Patient_Home', params: {lang: $i18n.locale} }" class="no-underline">
            {{$t('menu.Home')}}
            </router-link>
   
    </div>
</footer>

<FooterBlock/>
</template>

<style lang="scss">
.patient-home + .nav-footer {
    display:none;
}
.nav-footer a {
    padding:1rem;
}
.nav-footer .content {
    display: grid;
    //gap:1rem;
    grid-template-columns: 1fr 1fr;
    width:100%;
    border-top: 1px solid #ccc;
    margin-top: 2rem;
}

</style>

<script>
import PatientNavMenu from '@/views/patients/PatientNavMenu.vue'
import FooterBlock from '@/components/FooterBlock.vue';

export default {
    components: {
        PatientNavMenu,
        FooterBlock,
    },
    props: ['contents'],
    data() {
        return {
            // contents: contentData, 
        };
    },
    computed: {
		// locale(){
        //     // if (this.$route.params.lang && this.$route.params.lang != this.$i18n.locale){
        //     //     this.$root.$i18n.locale = 'en';
        //     // }
        //     if (this.$route.params.lang == 'es'){
        //         return 'Spanish';
        //     }
        //     if (this.$route.params.lang == 'en'){
        //         return 'English';
        //     }
        //     if (this.$i18n.locale == 'es'){
        //         return 'Spanish';
        //     }
		// 	return 'English';
		// },
        display_content() {
            let localizedContent = '';
            
            for (let i = 0; i < this.contents.content_items.length; i++) {
                let content = this.contents.content_items[i].display_contents.filter(
                    (display_content) =>
                        display_content.language == this.locale()
                );
                if (content.length){
                    localizedContent += content[0].display_content;
                }
            }             
            return localizedContent;
        },
    },
	methods:{
        locale(){
            if (this.$route.params.lang && this.$route.params.lang != this.$i18n.locale){
                this.$i18n.locale = this.$route.params.lang;
            }
            if (this.$route.params.lang == 'es'){
                return 'Spanish';
            }
            if (this.$route.params.lang == 'en'){
                return 'English';
            }
            if (this.$i18n.locale == 'es'){
                return 'Spanish';
            }
			return 'English';
		},
		getSectionContent(name){
			let localizedContent = '';
			let content_item = this.contents.content_items.filter(
                    (content_item) =>
                        content_item.item_name == name
			);
			if (content_item.length){
				// console.log(content_item[0].display_contents);
                localizedContent = content_item[0].display_contents.filter(
                    (display_content) =>
                        display_content.language == this.locale()
                );
				if(localizedContent.length){
					return localizedContent[0].display_content;
				}
					
			}
            return localizedContent;
		},
        getSectionCSSClass(name){
			let localizedContent = '';
			let content_item = this.contents.content_items.filter(
                    (content_item) =>
                        content_item.item_name == name
			);
			if (content_item.length){
				// console.log(content_item[0].display_contents);
                localizedContent = content_item[0].css_class;
					
			}
            return localizedContent;
		},
        clickLink(url){ 
            let propsData = { path: url+"/"+this.$route.params.lang }   
            let pieces = url.split("#");
            // console.log(pieces);
            if (pieces.length>1){
                propsData = { path: pieces[0], hash: "#"+pieces[1] } 
            }
 
            // let propsData = { path: url+"/"+this.$i18n.locale } 
            // console.log(propsData);

            this.$router.push(propsData)

        },
	},
    mounted(){
        var anchors = document.querySelectorAll('section.single-column article.content a') // Not iterable
        // console.log(anchors)
        Array.from(anchors).forEach(anchor => {
            // if(!anchor.hasAttribute('href'))
            //     return;
            const url = anchor.getAttribute('href')
            if (url.indexOf('http')>-1)
                return;
  
            if (url.indexOf('.pdf')>-1)
                return;
  
            anchor.addEventListener('click', event => {
                event.preventDefault();
                this.clickLink(url)
            });
        });
    },

}
</script>
