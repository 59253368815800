<template>
 <section class="language-picker">
      <language-globe />
      <p class="language-en">
          <a :class="isEN" @click="changeLocale('en')">en</a>
      </p>
      <p class="language-es">
          <a :class="isES"  @click="changeLocale('es')">es</a>
      </p>
  </section>
    <!-- <select v-model="$i18n.locale">
      <option
        v-for="(lang, i) in langs"
        :key="`lang-${i}`"
        :value="lang">
       
          {{ lang }}
      </option>
    </select> -->
</template>
<script>
import LanguageGlobe from '@/components/LanguageGlobe.vue'

export default {
  // name: 'SwitchLocale',
  components: {
      LanguageGlobe
  },
  data() {
    return { 
      // langs: ['en', 'es'],
      }
  },
  computed:{
    isEN(){
      if (this.$route.params.lang=='en') {
         return 'active';
      }
      return '';
    },
    isES(){
      if (this.$route.params.lang=='es') 
        return 'active';
      return '';
    },
  },
  methods: {
    changeLocale(val){
      this.$i18n.locale=val;
      // console.log(this.$route.name);
      const propsData = { name: this.$route.name, params: {lang:this.$i18n.locale} } 
      this.$router.push(propsData)
    }
  },
}
</script>
<style>
/* select {
    width: 150px;
    line-height: 1.1;
    outline: 0;
} */
p.language-en, p.language-es {
  cursor: pointer;
}
p.language-en a.active, p.language-es a.active {
  cursor: default;
}
</style>