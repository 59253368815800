<template>
    <LandingNavMenu/>

    <main class="landing-page"> 
    
    <!--link to patient landing page-->
    <router-link :to="{ name: 'Patient_Home', params:{'lang':'en'}}">
        <section class="landing-grid-section dizz-container">
            <div>
                <h3>For Patients</h3>
                <p>How to manage your dizziness symptoms</p>
            </div>
            
            <img src="img/circle-arrow_icon.png" alt="arrow to indicate link" />
        </section>
    </router-link>

    <!--link to physician landing page-->
    <router-link :to="{ name: 'Physician_Clinics'}">
        <section class="landing-grid-section dizz-container">
            <div>
                <h3>For Physicians</h3>
                <p>Evaluation & Management Algorithm</p>
            </div>
            <img src="img/circle-arrow_icon.png" alt="arrow to indicate link" />

        </section>
    </router-link>

    </main>
  <FooterBlock />
</template>

<script>
import LandingNavMenu from '@/components/LandingNavMenu.vue'
import FooterBlock from '@/components/FooterBlock.vue'

export default {
    components: {
        LandingNavMenu,
        FooterBlock
    },
    data() {
        return {
            contents: "", 
            // display_content: '' 
        };
    },
    computed: {
        display_content() {
            let localizedContent = '';
            let locale = 'English';
            if(this.$i18n.locale == 'es'){
                locale = 'Spanish';
            }
            for (let i = 0; i < this.contents.content_items.length; i++) {
                let content = this.contents.content_items[i].display_contents.filter(
                    (display_content) =>
                        display_content.language == locale
                );
                // console.log(content.length);
                if (content.length){
                    localizedContent += content[0].display_content;
                }
            }             
            return localizedContent;
        }
    }
}
</script>

<style lang="scss" >



</style>

