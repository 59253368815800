<template>
<PhysicianNavMenu/>
<!-- <main class="physician" :class="contents.css_class"> 
    <section class="single-column dizz-container"  :class="getSectionCSSClass('Main_content')">
		<article class="content">
        </article>
        
    </section>
</main> -->
<main class="physician" :class="contents.css_class"> 
    <section class="single-column ">
		<article class="content">

            <header class="category hero"  >
                <h4 v-if="getSectionContent('Page_intro')"  :class="getSectionCSSClass('Page_intro')" v-html="getSectionContent('Page_intro')"></h4>
            </header>
            
    <!-- goes in strapi-->
            <div class="strapi-content" v-html="getSectionContent('Main_content')"
                :class="getSectionCSSClass('Main_content')">
            </div>
    <!--end strapi-->

        </article>
    </section>


<!--     <section class="single-column container" :class="getSectionCSSClass('Header')" v-html="getSectionContent('Header')">
    </section> -->

    <!-- <div v-if="getSectionContent('Page_intro')" class="page-intro intro dizz-container full-width-background" :class="getSectionCSSClass('Page_intro')" v-html="getSectionContent('Page_intro')">
    </div> -->
<!-- 
    <section class="single-column dizz-container"  :class="getSectionCSSClass('Main_content')">
		<article class="content" v-html="getSectionContent('Main_content')">
        </article>
    </section> -->

</main>
<FooterBlock/>
</template>

<script>
import PhysicianNavMenu from '@/views/physicians/PhysicianNavMenu.vue'
import FooterBlock from '@/components/FooterBlock.vue'

export default {
    components: {
        PhysicianNavMenu,
        FooterBlock
    },
    props: ['contents', 'coords_list'],
    data() {
        return {
            // contents: contentData, 
        };
    },  
    computed: {
		locale(){
			if(this.$i18n.locale == 'es'){
                return 'Spanish';
            }
			return 'English';
		},
        display_content() {
            let localizedContent = '';
            
            for (let i = 0; i < this.contents.content_items.length; i++) {
                let content = this.contents.content_items[i].display_contents.filter(
                    (display_content) =>
                        display_content.language == this.locale
                );
                // console.log(content.length);
                if (content.length){
                    localizedContent += content[0].display_content;
                }
            }             
            return localizedContent;
        },
    },
	methods:{
		getSectionContent(name){
			let localizedContent = '';
			let content_item = this.contents.content_items.filter(
                    (content_item) =>
                        content_item.item_name == name
			);
			if (content_item.length){
				// console.log(content_item[0].display_contents);
                localizedContent = content_item[0].display_contents.filter(
                    (display_content) =>
                        display_content.language == this.locale
                );
				if(localizedContent.length){
					return localizedContent[0].display_content;
				}
					
			}
            return localizedContent;
		},
        getSectionCSSClass(name){
			let localizedContent = '';
			let content_item = this.contents.content_items.filter(
                    (content_item) =>
                        content_item.item_name == name
			);
			if (content_item.length){
				// console.log(content_item[0].display_contents);
                localizedContent = content_item[0].css_class;
					
			}
            return localizedContent;
		},
      clickBlock(name){
        this.$emit('click', name);
      },
	},
    mounted(){
        const anchors = document.querySelectorAll('section.single-column article.content .back') // iterable
        // console.log(anchors)
        Array.from(anchors).forEach(anchor => {  
            anchor.addEventListener('click', event => {
                event.preventDefault();
                this.$router.go(-1);
            });
        });
        const anchors_1 = document.querySelectorAll('section.single-column article.content .openModal')
        Array.from(anchors_1).forEach(anchor => {
            // console.log('mounted '+anchor.id)
  
            anchor.addEventListener('click', event => {
                event.preventDefault();
                this.clickBlock(anchor.id);
            });
        });
        const anchors_2 = document.querySelectorAll('div.page-intro  .openModal')
        Array.from(anchors_2).forEach(anchor => {
            // console.log('mounted '+anchor.id)
  
            anchor.addEventListener('click', event => {
                event.preventDefault();
                this.clickBlock(anchor.id);
            });
        });     
    },
    updated() {
        this.$route.go();
    },

}
</script>
