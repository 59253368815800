<template>
    <svg width="80" height="60" viewBox="0 0 80 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 8C0 3.58172 3.58172 0 8 0H72C76.4183 0 80 3.58172 80 8V52C80 56.4183 76.4183 60 72 60H8C3.58172 60 0 56.4183 0 52V8Z" fill="#ECEAEA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40.5 12.5C29.7341 12.5 21 20.7974 21 31.025C21 41.2526 29.7341 49.55 40.5 49.55C51.2659 49.55 60 41.2526 60 31.025C60 20.7974 51.2659 12.5 40.5 12.5ZM40.5 15.35C49.6091 15.35 57 22.3714 57 31.025C57 39.6786 49.6091 46.7 40.5 46.7C31.3909 46.7 24 39.6786 24 31.025C24 22.3714 31.3909 15.35 40.5 15.35ZM47.5097 31.8533L36.78 38.0111C36.4669 38.1893 36.0769 38.1933 35.7598 38.0215C35.4428 37.8496 35.2479 37.5287 35.25 37.182V24.8682C35.2479 24.5214 35.4428 24.2005 35.7598 24.0287C36.0769 23.8568 36.4669 23.8608 36.78 24.039L47.5097 30.1968C47.8138 30.3728 47.9994 30.6867 47.9994 31.0251C47.9994 31.3634 47.8138 31.6773 47.5097 31.8533Z" fill="#2782AC"/>
        <path d="M8 1H72V-1H8V1ZM79 8V52H81V8H79ZM72 59H8V61H72V59ZM1 52V8H-1V52H1ZM8 59C4.13401 59 1 55.866 1 52H-1C-1 56.9706 3.02944 61 8 61V59ZM79 52C79 55.866 75.866 59 72 59V61C76.9706 61 81 56.9706 81 52H79ZM72 1C75.866 1 79 4.13401 79 8H81C81 3.02944 76.9706 -1 72 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z" fill="#CDCACA"/>
    </svg>
</template>
<script>
export default {
  name: 'IconVideo',
  data() {
        return {
        };
  },
}
</script>
