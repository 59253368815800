<template>
    <PatientLayout :contents="contents" />
</template>

<script>
import contentData from "@/assets/pages/contents-Tips.json";
import PatientLayout from '@/views/patients/PatientLayout.vue'

export default {
    components: {
        PatientLayout
    },
    data() {
        return {
            contents: contentData, 
        };
    },
}
</script>

<style lang="scss">
</style>

