export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hola i18n"])},
  "main": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hola de ", _interpolate(_named("empresa"))])},
    "new_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Sin mensajes"]), _normalize(["un mensaje"]), _normalize([_interpolate(_named("cantidad")), " mensajes"])])}
  },
  "menu": {
    "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quiénes somos?"])},
    "Brief_episodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventos breves"])},
    "Constant_Dizziness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mareos constantes"])},
    "Other_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras causas"])},
    "Tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consejos para controlar los mareos"])},
    "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas frecuentes"])},
    "Dizziness_checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de verificación para el control de mareos"])},
    "Physicians": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médicos"])},
    "Patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio de los pacientes"])},
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])}
  },
  "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regentes de la Universidad de Michigan"])}
}