<template>
<ModalDefault :contents="contents" :visible="visible" @close="closeModal" />
</template>

<script>
// import contentData from "@/assets/pages/contents-About.json";
// import contentData from "@/assets/pages/contents-Constant_Dizziness.json";
import ModalDefault  from '@/components/ModalDefault.vue'
import { ref } from 'vue'

export default {
    components: {
      ModalDefault
    },
    props: ['visible', 'json_name'],
    data() {
        return {
            // contents: '', 
        };
    },
    methods:{
      closeModal(){
            this.$emit('close');
      },
    },
    setup(props) {
        let contents = ref({}); 

        import(`@/assets/pages/contents-${props.json_name}`).then((module) => {
            contents.value = module.default // modify var1 by assigning to var1.value
        });
        return {
            contents
        };
    },
};
</script>
