<template>
  <PhysicianNavMenu />
  <div class="pdf">
    <div id="pan-zoom" class="pdf-holder">
      <img src="@/assets/algorithm.png" alt="" usemap="#workmap" />
      <div class="goto goto-1" @click="goto('Signs_of_a_stroke')"></div>
      <div class="goto goto-2" @click="goto('Types_of_Nystagmus')"></div>
      <div class="goto goto-3" @click="goto('Neurological_Abnormality')"></div>
      <div class="goto goto-4" @click="goto('Dix-Hallpike_Test')"></div>
      <div class="goto goto-5" @click="goto('Nystagmus_Types')"></div>
      <div class="goto goto-6 no-link"></div>
      <div class="goto goto-7 no-link"></div>
      <div
        class="goto goto-8"
        @click="goto('Unidirectional_horizontal_nystagmus')"
      ></div>
      <div class="goto goto-9" @click="goto('Other_pattern_nystagmus')"></div>
      <div class="goto goto-10" @click="goto('BPPV')"></div>
      <div class="goto goto-11" @click="goto('Vestibular_Neuritis')"></div>
      <div class="goto goto-12" @click="goto('Non_specific_dizziness')"></div>
      <div
        class="goto goto-13"
        @click="goto('Unilateral_vestibulopathy')"
      ></div>
      <div class="goto goto-14" @click="goto('Epley_Maneuver')"></div>
      <div
        class="goto goto-15"
        @click="goto('Medication_Vestibular_Exercises')"
      ></div>
      <div class="goto goto-16" @click="goto('Vestibular_PT_Medication')"></div>
      <div class="goto goto-17 no-link"></div>
      <div class="goto goto-18" @click="goto('Followup_with_PCP')"></div>
      <div class="goto goto-19" @click="goto('Consider_consult')"></div>
    </div>
  </div>
  <FooterBlock />
</template>

<script>
// If needed, here is the import for pinch-zoom-js, going to try without it first
//const PinchZoom = require("pinch-zoom-js/dist/pinch-zoom.min.js");
import PhysicianNavMenu from "@/views/physicians/PhysicianNavMenu.vue";
import FooterBlock from "@/components/FooterBlock.vue";
const panzoom = require("panzoom/dist/panzoom.min.js");

export default {
  components: {
    PhysicianNavMenu,
    FooterBlock,
  },
  mounted() {
    let viewportWidth = window.innerWidth;
    const zoom = viewportWidth < 480 ? 4 : 1.5;
    // PanZoom Docs
    // https://github.com/anvaka/panzoom
    const element = document.getElementById("pan-zoom");
    panzoom(element, {
      initialZoom: zoom,
      onTouch: function () {
        // if you would like to capture the touch event
        // add the "e" parameter to the function

        // By default panzoom will preventDefault on touch events
        return false; // tells the library to not preventDefault
      },
    });
  },
  methods: {
    goto(name) {
      window.location.href = `/physician/AlgorithmPage/${name}`;
    },
  },
};
</script>

<style scoped>
.site-footer {
  margin-top: 0;
}
.pdf {
  overflow: hidden;
  width: 100vw;
  flex-grow: 1;
  padding-top: 2rem;
  padding-left: 1.5rem;
  background: #ffffff;
}
.pdf-holder {
  position: relative;
  width: 100%;
}
.pdf-holder img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.goto {
  position: absolute;
  cursor: pointer;
}
.no-link {
  cursor: default;
}
.goto-1 {
  top: 12%;
  left: 0;
  width: 11%;
  height: 30%;
}
.goto-2 {
  top: 12%;
  left: 12%;
  width: 11%;
  height: 30%;
}
.goto-3 {
  top: 51%;
  left: 0;
  width: 11%;
  height: 10%;
}
.goto-4 {
  top: 21%;
  left: 25%;
  width: 10%;
  height: 10%;
}
.goto-5 {
  top: 21%;
  left: 37%;
  width: 10%;
  height: 10%;
}
.goto-6 {
  top: 33%;
  left: 37%;
  width: 10%;
  height: 10%;
}
.goto-7 {
  top: 46%;
  left: 37%;
  width: 10%;
  height: 10%;
}
.goto-8 {
  top: 62%;
  left: 37%;
  width: 10%;
  height: 10%;
}
.goto-9 {
  top: 73%;
  left: 37%;
  width: 10%;
  height: 10%;
}
.goto-10 {
  top: 21%;
  left: 49%;
  width: 10%;
  height: 10%;
}
.goto-11 {
  top: 33%;
  left: 49%;
  width: 10%;
  height: 11%;
}
.goto-12 {
  top: 46%;
  left: 49%;
  width: 10%;
  height: 10%;
}
.goto-13 {
  top: 61%;
  left: 49%;
  width: 10%;
  height: 13%;
}
.goto-14 {
  top: 21%;
  left: 61%;
  width: 10%;
  height: 10%;
}
.goto-15 {
  top: 33%;
  left: 61%;
  width: 10%;
  height: 10%;
}
.goto-16 {
  top: 62%;
  left: 61%;
  width: 10%;
  height: 10%;
}
.goto-17 {
  top: 11%;
  left: 73%;
  width: 13%;
  height: 31%;
}
.goto-18 {
  top: 21%;
  left: 87%;
  width: 11%;
  height: 20%;
}
.goto-19 {
  top: 48%;
  left: 87%;
  width: 11%;
  height: 12%;
}
</style>
