<template>
<main class="landing-page"> 
    
<p>content below this will be the actual landing page for dizzy.help</p>

<a href="#"><!--link to patient landing page-->
    <section class="landing-grid-section container">
        <div>
            <h3>For Patients</h3>
            <p>How to manage your dizziness symptoms</p>
        </div>
        
        <img src="img/circle-arrow_icon.png" alt="arrow to indicate link" />
    </section>
</a>

<a href="#"><!--link to physician landing page-->
    <section class="landing-grid-section container">
        <div>
            <h3>For Physicians</h3>
            <p>Diagnosis algrorithm</p>
        </div>
         <img src="img/circle-arrow_icon.png" alt="arrow to indicate link" />

    </section>
</a>

<p>end content for landing page for dizzy.help</p>



    <section  class="hero intro container full-width-background">
        <h4>section title when appropriate<span>h4</span></h4>
        <h1>page title<span>h1</span></h1>
        <p>when appropriate<span>p</span></p>
    </section>

    <section class="page-intro intro container full-width-background">
        <h2>page heading h2 (if exists)<span>h2</span></h2>
        <p>when appropriate<span>p</span></p>
    </section>

    <section class="single-column container">
		<article class="content">

            <h3>h3 heading<span>h3</span></h3>
            <p>Paragraph text<span>p</span></p>
            <p>For <strong>brief episodes of dizziness especially when moving your head</strong>, it can sometimes take more than one Epley maneuver to relieve symptoms. <span>p</span></p>

            <h4>h4 heading and tag to use for faq answer<span>h4</span></h4>
            <p class="note">with class "note" will look like this, which is smaller than em/i text in a regular paragraph.<span>p class="note"</span></p>
            <ul>
                <li>When this system is working normally, your eyes are still when you are sitting or walking. <span>li</span></li>
                <li>If you have vestibular neuritis, your eyes start moving back and forth in a way you can't control. This eye movement is called nystagmus. It makes you feel like you are moving even when you aren't.<span>li</span></li>
            </ul>
            <h3>faq heading<span>h3</span></h3>
            <h4>answer<span>h4</span></h4>
            <p>faq answer content<span>p</span></p>
            <button>button</button>
            <button><a href="#">button with a link</a></button>
            <a class="button" href="#">a with class="button"</a>


            <div class="video video-accordion-wrapper"><span>div class="video video-accordion-wrapper"</span>
                <h3>Before you begin, try some deep breathing<span>h3 accordion heading</span></h3>
                <h5>Level 1<span>h5</span></h5>
				<div class="videoWrapper"><span>div class="videoWrapper"</span>
                <span>then the youtube iframe inside videoWrapper</span>
					<iframe width="560" height="315" src="https://www.youtube.com/embed/GdzrrWA8e7A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
				<div class="video-accordion accordion">
                    <span>div class="video-accordion accordion"</span>
					<div class="accordion-section">
                        <span>div class="accordion-section"</span><span>followed by an input and i tags, since this is css not javascript</span>
						<input type="checkbox" checked>
						<i></i>
						<h4 class="accordion-title">Open Instructions <span>h4 class="accordion-title"</span></h4>
						<div class="accordion-body">
							<h3>a title <span>h3</span></h3>
							<p>some content<span>p</span></p>
							<h4>Repeat<span>h4</span></h4>
                            <p class="time">time 2-3 minutes <span>p class="time"</span></p>
                            <p class="repeat">3 times a day<span>p class="repeat"</span></p>

                            <h4>Moving On<span>h4</span></h4>
                            <p>When you tolerate this exercise, you can move to level 2, standing up.</p>
						</div>
					</div>
				</div>
			</div>

            <h4>tips to manage dizziness accordion section<span>h4</span></h4>
				<div class="tips-accordion accordion">
					<div class="accordion-section">
						<input type="checkbox" checked>
						<i></i>
						<h3 class="question accordion-title">title content here<span>h3 class="question accordion-title"</span></h3>
						<div class="accordion-body">
							<p>some accordion content<span>p</span></p>
							<ul>
								<li>Deep breathing helps you relax by lowering your blood pressure and heart rate.<span>li</span></li>
								<li>Deep breathing can be helpful at times of stress and also as a daily relaxation exercise. <span>li</span></li>
								<li>It’s a simple exercise that’s easy to learn and you can do it anywhere — no equipment needed!<span>li</span></li>
							</ul>
							<h4>Exercise to try<span>h4</span></h4>
						</div>
					</div>
					<div class="accordion-section">
						<input type="checkbox" checked>
						<i></i>
						<h3 class="question accordion-title">another title<span>h3 class="question accordion-title"</span></h3>
						<div class="accordion-body">
							<p>some more accordion content<span>p</span></p>
							<ul>
								<li>Deep breathing helps you relax by lowering your blood pressure and heart rate.<span>li</span></li>
								<li>Deep breathing can be helpful at times of stress and also as a daily relaxation exercise. <span>li</span></li>
								<li>It’s a simple exercise that’s easy to learn and you can do it anywhere — no equipment needed!<span>li</span></li>
							</ul>
							<h4>Exercise to try<span>h4</span></h4>
						</div>
					</div>
			</div>

            <p>{{display_content}}</p>

   
            <p>{{$i18n.locale}}</p>
        </article>
    </section>
</main>


  
  
</template>

<script>
import contentData from "@/assets/pages/contents-content-1.json";

export default {
    data() {
        return {
            contents: contentData, 
            // display_content: '' 
        };
    },
    computed: {
        display_content() {
            let localizedContent = '';
            let locale = 'English';
            if(this.$i18n.locale == 'es'){
                locale = 'Spanish';
            }
            for (let i = 0; i < this.contents.content_items.length; i++) {
                let content = this.contents.content_items[i].display_contents.filter(
                    (display_content) =>
                        display_content.language == locale
                );
                // console.log(content.length);
                if (content.length){
                    localizedContent += content[0].display_content;
                }
            }             
            return localizedContent;
        }
    }
}
</script>

<style lang="scss" >



</style>

